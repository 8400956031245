@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}

:root{
    --primary_color:rgb(255,204,5);
}
.Banner_header_header{
    background-color: rgb(28,28,28);
}
.follow_us{
    display:flex;
    flex-wrap:wrap;
    padding: 10px;
    justify-content: flex-start;
    gap: 20px;
}
.follow_us_p{
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
    text-transform: uppercase;
   

}
.follow_us_i{
    color: white;
    font-size: 14px;
    margin-top: 10px;
}
.login_button_col{
    display:flex;
    justify-content: flex-end;
     padding: 10px;
}
.login_button{
  padding: 10px 20px;
 
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
 font-size: 14px;
  text-transform: uppercase;
  background-color:var(--primary_color);
  color: white;

}

@media only screen and (max-width:1200px){
    .follow_us{
        display:none;
    }
}