.unesco_container{
    background: url(./Images/the-cardamom-hills-kodaikanal-1657516597_881b7105c4614958cca0\ \(1\).webp);
    min-height: 200px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.unesco_container-2{
    background-color: rgba(0, 0, 0, 0.379);
}
.unesco_container-2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    
   
}
.unesco_wonders_col_1_h1{
    color: rgb(255, 255, 255);
 width: auto;
 height:auto;
text-align: center;
text-decoration: underline;
    font-family: serif;
}
.unesco_wonders_col_1{

   width: 100%;
    align-items:center;
    text-align:justify;
  
    color: rgb(255, 255, 255);
}
.unesco_wonders_col_1{

    font-family: serif;
    width:auto;
    height:auto;
}
.unesco_wonders_col_1_p{
    width: 100%;
    text-align:justify;
}

