.tour_package_banner{
    background-image: url('./images//carousel-2.jpg');
    background-attachment: fixed;
   
    background-repeat: no-repeat;
    background-size: cover;
      color: white;
      min-height:400px;
}
.tour_packahe_h1{
    font-size: 82px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:400px;
  
    background-color: rgba(0, 0, 0, 0.379);
}

.packages_list_card_container {
    width: 100%; /* Set the width to accommodate the scaled image */
    overflow: hidden;
    position: relative;
  }
  
  .package_list_card_image{
    width: 100%;
    height:300px !important;
    height: auto;
    filter: brightness(100%);
    filter: contrast(110%);
    transition: transform .4s ease-in-out;
  }
  
  .packages_list_card_container:hover .package_list_card_image {
    transform: scale(1.2);
    
  }
  
  .package_list_card_image_p {
    position: absolute;
    bottom: 5%;
    font-size:22px;
    width:80%;
    left: 50%;
    text-align:center;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.533);
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  .package_list_card_p{
    text-align: justify;
    font-size: 14px;
    color:var(--para_color);
  }