@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

:root{
    --thick_brown:rgb(100,34,33);
    --light_brown:rgb(126,103,94);
}

.jeep_h1{
    color:var(--thick_brown);
    
    margin-top:20px;
    margin-bottom:60px;
    font-family: "Pacifico", cursive;
    font-weight: 400;
}

.jeep_p{
    color:black;
    font-family: cursive;
    font-weight: 800;
    font-size: 15px;
    text-align:justify;
   
}

.jeep_button{
    color:white;
    border: none;
    background-color:var(--light_brown);
}
.TruckService_container{
    width: 100%;
    height:auto;
 background-image: url(./images//bg-prefooter2.jpg);
 background-repeat: no-repeat;
 background-size: cover;
 object-fit: cover;
}

.Side_truck_image{
    width: 100%;
    height:400px;
    object-fit:cover;
    background-color: white;
   
}

.truck_one_image{
    width:90%;
}
.jeep_card_service_col_1{
    position: relative;
  
    background-color: white;
 
   
}
.jeep_card_service_col_2{
    position: relative;

    background-color: white;
 
   
}
.jeep_card_details_1_top{
    position:absolute;
    top:50px;
    bottom:50px;
    left: 50px;

  
}
.jeep_card_details_1_top_h6{
    background-color: rgba(0, 0, 0, 0.593);
    border-radius: 40px;
    text-transform: uppercase;
    color:white;
}

.jeep_card_details_2_top_h6{
  height: 35px;
  background-color: rgba(0, 0, 0, 0.593);
    border-radius: 40px; 
font-weight: bold; 
color:white;

}
.jeep-_card_details_2_top{
    position:absolute;
    top:450px;
    bottom:50px;
    left: 50px;
display: flex;


}

.jeep_card_service_image{
    width:100%;
    object-fit: cover;
    height: 500px;
}

.jeep_two_btns{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.jeep_card_side_head_h3{
    font-family: "Pacifico", cursive;
    font-weight: 400;
}
.jeep_card_side_head_p{
    font-family: cursive;
    color: var(--para_color);
    text-align:justify;
}

.jeep_btns_1{
   margin-bottom: 10px;
    border:none;
    background-color: rgb(254,104,49);
    color:white;
    padding: 20px 60px;
}

.jeep_btns_2{
    margin-bottom: 10px;
    border:none;
    background-color:  rgb(255,232,224);
    padding: 20px 60px;
}