.contact_us_h1_container{
    background-image: url('./images/contact-us-2-62fa2cc2edbaf-sej-760x400.png');
    background-attachment: fixed;
   
    background-repeat: no-repeat;
    background-size: cover;
      color: white;
      min-height:400px; 
}

.contact_us_h1{
    font-size: 82px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:400px;
  
    background-color: rgba(0, 0, 0, 0.379);
}

.input_form{
    width: 100%;
}
.fill_the_form{
    font-size:42px;
}

.footer_social_medium_icon{
    display: flex;
    justify-content:space-between;
    flex-wrap:wrap;
}

@media only screen and (max-width: 300px) {
    .contact_us_h1{
      font-size:32px;
    }
    
  }