.tariff_banner{
    /* The image used */
  background-image: url('../Component/images/full-shot-travel-concept-with-landmarks.jpg');

  /* Full height */
  height: 400px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tariff_h1{
    color:white;
    font-size: 53px;
    font-weight:bold;
    display:flex;
    justify-content:center;
    align-items:center;
    height:400px;
    background-color:rgba(0, 0, 0, 0.526);
}

.th{
    background-color: var(--primary_color) !important;

}

.td{
    font-weight:bolder !important;
}

@media only screen and (max-width: 900px) {

  
        .custom-table-scroll_big{
            width: 500px;
        height: 400px;
        overflow: scroll;  
        
    }}

  @media only screen and (max-width: 600px) {
    .custom-table-scroll {
   overflow: auto;
        width: 400px;
        height: 300px;
        overflow: scroll;
        }
        .custom-table-scroll_big{

        }
  }

  @media only screen and (max-width: 450px) {
    .custom-table-scroll {
   overflow: auto;
        width: 300px;
        height: 300px;
        overflow: scroll;
        }
  }