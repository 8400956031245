.refund_container_div{
   
}

.refund_image{
    width: 100%;
    height:500px;
    object-fit: cover;
}

.refund_h1{
 
}
.refund_h1_span{
    color: rgb(255,104,97);
}

.refund_para{
   
    text-align: justify;
}

.refund_strong{
    color:var(--green_btn) !important;
    font-size: 20px;
}