.who_container{
    background-image: url('./Images//h1-img-9.jpg');
    height: auto;
    width: 100%;
    padding: 15px;
}
.who_container_2{
    margin-top: 50px;
}
.Carousel_images{
    object-fit: cover;
    width:100%;

}

/* Add this CSS to your existing CSS file or create a new one */

/* Hide the carousel indicators */
.carousel-indicators {
    display: none;
  }
  
  /* Hide the next and previous icons */
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  
  .who_we_are_content_h1{
    font-family: serif;
    font-size: 50px;
    line-height:54px;
    color:black;
  }

  .who_we_are_content_p{
    font-family: serif;
    font-size: 16px;
    text-align: justify;
   
    color: rgb(119,119,120);
  }

  .who_we_are_btn{
    background-color: var(--primary_color);
    border:none;
    padding:10px 25px;
    color:white;
    
  }

  @media only screen and (max-width:400px){
.who_we_are_content_h1{
  font-size:32px;
  display:flex;
  justify-content:center;
}
.who_we_are_content_h1{
  font-size:22px;
}

  }

  @media only screen and (max-width: 350px) {
    
    .Carousel_images{
      height:300px;
    }
  }