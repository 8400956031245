@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.home {
  position: relative;
}

.video {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.content_section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 1;
}

.content_section_h1 {
  font-size: 52px;
  font-family: serif;
  font-weight: 900;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  width: 100%; /* Take up full width */
}

.content_section_gif_content {
  font-family: "Dancing Script", cursive;
  font-size: 32px;
  color: var(--primary_color);
}

.content_section_img {
  display: flex;
}

.content_section_p {
  font-size: 18px;
  font-weight: 700;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  width: 100%; /* Take up full width */
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(4, 7, 7);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary_color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
