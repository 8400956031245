:root{
    --para_color:rgb(159,159,165);
}


.container-about-us{
    background-color: rgb(247,246,246);
    height: auto;
}



.happy_customer{
    /* border: 1px solid black; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.happy_emoji{
    font-size: 102px;
}
.happy_emoji_icon{
border-radius: 50%;
    
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    
}

.happy_customer_h4{
    font-size: 20px;
    width: auto;
}

.happy_customer_p{
    font-size: 15px;
    color: var(--para_color);
   
}

.about_btn_read_more{
    padding:10px 30px;
    border:none;
    background-color:var(--primary_color);
    color:white;
}

.choose_us_hr{
width: 20%;
height: 10px;
border-radius: 20px;
color:var(--primary_color);
background-color: var(--primary_color);


}