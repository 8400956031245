.transport_car{
  /* border:1px solid black */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.offer_container{

  height:auto;
  width:100%;
}

.transport_car_img{
  width:100%;
  overflow:hidden;
 
}

.transport_car_img_1{
  width:100%;
  height:200px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.transport_car_img_1:hover{
 transform:scale(1.2);
 transition: all 0.4s ease-in-out;
}

/* Card Details start*/
.transport_details{
  display: flex;
  justify-content: space-between;
  text-decoration:none;
  list-style:none;
  flex-wrap: wrap;
  color: var(--para_color);
}

/* Tick_color */
.tick{
  color:green;
  font-size: 22px;
  margin-right: 9px;
}

.car_rental_h1{
  color:black;
  font-size: 30px;
  
}

.car_rental_hr{
  background-color: var(--primary_color);
  height: 5px;
  border-radius: 20px;
  color: var(--para_color);
  width: 30%;


}

.know_more_btn{
  padding:10px 30px;
  border:none;
  background-color:var(--primary_color);
  color:white;
}
:root{
  --light_orange:rgb(255,124,91);
}

.seden_car_image{
width:100%;
height: 400px;
border:1px solid rgb(245,246,249);
background-color:rgb(245,246,249);
}
.seden_cars_col{
  position: relative;
}
.seden_car_h3{
position:absolute;
top:85%;
font-weight:bold;
left:40%;
color:white;
transform:translate(-50%,-50%);
}

.transport_know_more{
  background-color:var(--light_orange);
  color:white;
  text-align:center;
}

.taxi_offer{
  color:var(--para_color);
}

.offer_container{
  background-color:rgb(57,56,56);
}







.rental_cars{
    width: 100%;
    height:200px;
}
.rental_car_card{
    border:1px solid black;
}

.rental_car_banner{
    background-image: url(./images//100\ Beautiful\ Places\ in\ India.jpeg);
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
      color: white;
      min-height:400px;
   
}
.rental_car_banner_h1{
  font-size: 82px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  height:400px;
  background-color: rgba(0, 0, 0, 0.379);
   
}


.faq{
  font-size: 42px;
  text-decoration: underline var(--primary_color);

}

@media only screen and (max-width: 450px) {
  .rental_car_banner_h1{
    font-size:32px;
  }
  
}



@media only screen and (max-width: 350px) {
  
.seden_car_image{
  height: 250px;
}
.seden_car_h3{
  margin-left: 20px;
  margin-top: -20px;
}
}