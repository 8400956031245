.place_container_home_page{
  height: auto;
  width:100%;
  background-color:rgb(245,246,249);
}

.important_place_h1{
  font-family: serif;
  font-size: 30px;
  font-weight: 600;
}
.important_place_p{
  font-size: 16px;
color: var(--para_color);
}
.attraction_h1{
  color: var(--primary_color);
  text-decoration: underline ;
}
.top_place_images{
  width: 100%;
  
 
 
}

.top_place {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.package_1 {
  position: absolute;
  top: 5px; 
left: 10px;

  background-color: rgba(255, 255, 255, 0.7); /* Optional: Add a background color for better visibility */
  padding: 5px; /* Optional: Add padding for better readability */
  border-radius: 5px; /* Optional: Add border radius for better aesthetics */
}

.top_place_h5{
  font-size: 16px;
}
.top_place_p{
  font-size: 14px;
  color: var(--para_color);
}

.place_know_btn{
  border: none;
  background-color:var(--primary_color);
  color: white;
}
