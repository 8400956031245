.hotel_details_page_container{
    background-color: rgb(246,246,246);
    height: auto;
}


.hotel_banner{
    background-image: url(./images//Luxury\ Resort.jpeg);
    
  /* Set a specific height */
  min-height: 400px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

}
.hotel_banner_h1{
    font-size: 82px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:400px;
font-weight: bold;
    background-color: rgba(0, 0, 0, 0.379);
}
.hotel_table{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.hotel_table_img{
    width:100%;
    height:250px;
   
}

.icon_section_in_hotels_page{
    display: flex;
  
    width:100%;
    gap: 130px;
    align-items: center;
    height:auto;
}