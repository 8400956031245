.about_us_container_h1{
    background-image: url('./images//bg-hero.jpg');
    background-attachment: fixed;

    background-repeat: no-repeat;
    background-size: cover;
    color: white;
      min-height:400px;
      
    
}
.about_h1{
    font-size: 82px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:400px;
  
    background-color: rgba(0, 0, 0, 0.379);
}


.side_image_in_about_us{
    width:100%;
    height:500px;
}

.Welcome_to_Tourist_h1{
    font-size:45px;
    
    font-weight:bold;
    margin-top:-30px;
}
.Tourist{
    color:var(--primary_color);
  
}
.about_us_right_side_content_container{
    text-align: justify;
    transition:1s;
}

.about_us_para{
    color:var(--para_color);
    font-size:16px;
    transition:1s;
}

.money_refund_image{
    width:100%;
    height:300px;
}
.tick_about{
    color:var(--green_btn);
    font-size:32px;
}
.strong{
    color:black !important;
}
.Best_Travel_Agency_h3{
    font-weight: bold;
}
.over_image_col{
    display:flex;
    flex-wrap: wrap;
}



