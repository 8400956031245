.footer_col_1_p{
    text-align: justify;
    font-size: 15px;
}
.footer_icon{
    border:1px solid white;
    font-size: 45px;
    color: white;
}
.money_card_container{
    display: flex;
    flex-wrap:wrap;
}
.footer_icon_1{
    font-size: 22px;
    height: 30px;
    
}
.footer_money_card_1{
    width: 90px;
    height:70px;
    background-color:white;
    

}
.footer_col_1_h1{
    font-size: 30px;
    text-decoration: underline;
}

.footer_container{
    background-color: rgb(57, 56, 56);
    color: white;
}

.qr{
    width:100%;
    height:250px;
}

.what_icon_fixed{
    color:rgb(5, 241, 5);
    position:fixed;
    right: 3%;
    bottom:14%;
    z-index:1;
    font-size: 50px;
    cursor: pointer;
}

.call_icon_fixed{
    color:rgb(5, 29, 241);
    position:fixed;
    right: 3%;
    bottom:3%;
    z-index:1;
    font-size: 50px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
}

.p_1_1 {
   background-color:rgb(255,204,5);
color: black;
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 2px solid rgba( 255, 255, 255, 0.18 );
    animation: blink-shadow 2s infinite alternate;
}

@keyframes blink-shadow {
    0% {
        box-shadow: rgba(255, 255, 255, 0.411) 0px 54px 55px, rgba(242, 240, 240, 0.822) 0px -12px 30px, rgba(238, 237, 237, 0.12) 0px 4px 6px, rgba(255, 252, 252, 0.17) 0px 12px 13px, rgba(255, 254, 254, 0.09) 0px -3px 5px;
    }

    100% {
        box-shadow: none;
    }
}



  