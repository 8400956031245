.family_package_banner{
    background-image: url('./images//Family\ Package.jpg');
    background-attachment: fixed;
   
    background-repeat: no-repeat;
    background-size: cover;
      color: white;
      min-height:420px;
}


.family_package_banner_h1{
    font-size: 82px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:420px;
  
    background-color: rgba(0, 0, 0, 0.379);
}

.kodaikanal_Family_package{
    width:100%;
    object-fit: cover;

}

.honey_moon_package_box_col {
    position: relative;
}

.honey_moon_package_box_col:hover .honey_moon_package_box_img {
    transform: scale(1.6);
}

.all_three_packages_top_para{
    color: var(--para_color);
}

.all_three_packages_container{
    background-color: rgb(242,242,242);
    width:100%;
    height:auto;
}
.honey_moon_package_box {
    width: 350px;
    overflow: hidden;
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.honey_moon_package_box_img {
    width: 100%;
    height: 100%;
    filter: brightness(80%);
    -webkit-transition: all .4s ease-in-out;
}

.honey_moon_package_box_description {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: calc(100% - 40px);
    max-width: 300px;
    z-index: 2;
}

.honey_moon_package_box_btn {
    color: white;
    background: transparent;
    border: 1px solid white;
}
.our_advantages_div{
    background-color: rgb(57,56,56);
    color: white !important;
}
  .should_choose_image{
    width:100%;
    height:400px;
    object-fit: cover;
    background-color: white;
    
  }
  .four_packages_tour{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .faq_images{
    width:100%;
  }
  .package_icons_family{
    color:var(--orange_btn);
    font-size: 22px;
    font-weight:bold;
  }

  .tour_para{
    color:var(--para_color)
  }

 
  
  @media only screen and (max-width: 350px) {
.family_package_banner_h1{
    font-size:32px;
}
.family_package_banner{
    height:200px;
}
  }