.travel_banner{
    background-image: url(./Images//home_bg_1.jpg);
     /* Set a specific height */
  min-height: 200px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.travel_banner_content_container{


  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.433);
}
.travel_banner_h1 {
    font-weight: bold;
    color:white;
    display: flex;
    justify-content: center;
    align-items:center;
    height: 100px; 
  
 

}

.travel_banner_p {
  font-weight: bold;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;

  

}


/* @media only screen and (max-width: 350px) {
    
    .travel_banner_p{
      display: none;
    }
    .travel_banner{
        height: 250px;
    }
  } */