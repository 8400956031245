.navbar_container{
    
    position: sticky;
    top:0;
    z-index: 100;
    background-color: rgb(245,246,249) !important;
    
}
.nav_details_container{
    gap:50px;
}
.nav_details{
    font-size: 14px;
    font-weight:600;
 text-transform: uppercase;
}

.nav-link.active {
   background-color:var(--primary_color);
   color:white !important;
   padding:10px 25px !important;
  }
  
  .nav_details_content{
    margin-top:15px;
  }

  @media only screen and (max-width: 1300px) {
    .nav_details_container {
        display:none;
        visibility: hidden;
    }
  
  }