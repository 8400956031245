.jeep_safari_conatiner{
    background-image: url('./images/jeep\ raid\ page\ Banner.jpg');
    background-attachment: fixed;
   
    background-repeat: no-repeat;
    background-size: cover;
      color: white;
      min-height:400px; 
      object-fit:cover;
}
.jeep_safari_h1{
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height:400px; 
    font-weight:bold;
    font-size:82px;
    color:white;
    background-color:rgba(0, 0, 0, 0.604);
}
.off_road_container{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.jeep_page_h1{
    color: var(--green_btn);
    font-family: "Pacifico", cursive;
}
.jeep_icon{
    font-size:93px;
    color: var(--green_btn);
}
.off_road_safari_p{
    color:var(--para_color);
    text-align:justify;

    font-weight: 600;
}

.Service_img_1{
    width:100%;
    height:250px;
    object-fit:cover;

}
.jeep_service_container{
    border-bottom: dashed;
    
}
@media only screen and (max-width: 450px) {
.jeep_safari_h1{
    font-size:32px;
}
}