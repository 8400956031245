:root{
    --green_btn:rgb(25,140,25);
    --orange_btn:rgb(247,160,0);
}
.help_you_container_all{
  background-image: url(../images/pattern-img1.webp);
/* background-color: var(--para_color); */
    width: 100%;
    height:auto;
    background-repeat: no-repeat;
    background-size: cover;
}
.help_you_col_1{
    width:100%;
    overflow: hidden;
}

.help_you_p{
    font-size: 16px;
    color:var(--para_color)
}
.click_here{
    border: 1px solid white;
    background-color: var(--orange_btn);
    color: white;
    display: flex;
    
    justify-content: center;
    cursor: pointer;
    font-weight: 600;

}

.Kodaikanal_Car_rental_package_image{
    width:100%;
    -webkit-transition: all .4s ease-in-out;
height: 200px;
}

.Kodaikanal_Car_rental_package_image:hover{
    transform: scale(1.6);
 
}

